@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply m-0 bg-black text-white !scrollbar-thin !scrollbar-track-transparent !scrollbar-thumb-green;
  }
}

@layer components {
  .kk-input-form {
    @apply form-input border-y-0 border-x-0 border-b-2 border-green bg-transparent rounded focus:ring-2 focus:ring-green focus:border-transparent focus:transition focus:duration-200 focus:ease-in-out;
  }
}
